<style scoped lang="scss">

</style>

<template>
  <div class="page-maintenance-dayworks">
    <div class="toolbars mrgb5">
        <div class="buttons mrgb5">
            <div class="fr">
                <el-input class="c-el-input mrgr5 mrgb5" placeholder="员工的姓名/手机号" clearable v-model="listQuery.keywords"
                    size="medium"></el-input>
                <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium"
                    :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" @change="_search"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    align="right">
                </el-date-picker>
                <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                <el-button class="mrgb5" type="warning" size="medium" @click="_updateState()"><i class="el-icon-refresh"></i> 更新状态</el-button>
                <el-button class="mrgb5" type="danger" size="medium" @click="openExportExcel()"><i class="fa fa-sign-out"></i> 导出</el-button>
            </div>
            <div class="clearb"></div>
        </div>
    </div>
    <el-table :data="tableData" style="width: 100%" border size="medium">
        <!--<el-table-column label="ID" prop="id" min-width="120"></el-table-column>-->
        <el-table-column label="日期" prop="date"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="联系电话" prop="tel" width="160"></el-table-column>
        <el-table-column label="首次工作时间" prop="startTime"></el-table-column>
        <el-table-column label="最后工作时间" prop="endTime"></el-table-column>
        <el-table-column label="有效工作时间" prop="durationTxt"></el-table-column>
        <el-table-column label="有效挪车量" prop="effectiveMoveCount"></el-table-column>
        <el-table-column label="挪车量" prop="moveCount"></el-table-column>
        <el-table-column label="换电量" prop="batteryChangeCount"></el-table-column>
        <el-table-column label="巡检量" prop="inspectionCount"></el-table-column>
        <el-table-column label="维修量" prop="reparionCount"></el-table-column>
        <el-table-column label="工作状态" prop="statusDesc">
            <template slot-scope="scope">
                {{ tranceEmployeeDayWorkStatus(scope.row.status) }}
            </template>
        </el-table-column>
    </el-table>

    <div class="pagination-container" v-if="isPagination">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
            :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>

    <!--导出excel-->
    <export-to-excel :ref="refExportToExcel"></export-to-excel>
  </div>
</template>

<script>
  import permission from "@/mixins/permission"
  import enumConfigs from "@/mixins/enumConfigs"
  import * as funMaintenance from '@/api/maintenance'
  import moment from "moment"
  import ExportToExcel from "@/components/Excels/JsonToExcel"
  import { EmployeeDayWorkLogs } from "@/mixins/commonPageExport"
  export default {
    name: "pageMaintenanceDayWorks",
    mixins: [permission, enumConfigs],
    components: { ExportToExcel },
    data() {
      let that = this
      return {
        refExportToExcel: "refDayWorksToExportExcel",
        tableData: [],
        total: null,
        listQuery: {
            area: "",
            keywords: "",
            startDate: "",
            endDate: "",
            page: 1,
            size: window.$common.appPageSize
        },
        isPagination: false,
        selectedTime: [moment().subtract(1, "d").format("YYYY-MM-DD 00:00:00"), moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")],
        pickerOptions: {
            shortcuts: [
                {
                    text: "昨天",
                    onClick: (picker) => {
                        let timeArr = that.setPickerTime(picker, 1, "days")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一周",
                    onClick: (picker) => {
                        let timeArr = that.setPickerTime(picker, 1, "weeks")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一月",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 1, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近三月",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 3, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近半年",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 6, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一年",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 1, "years")
                        that.setQueryTime(timeArr)
                    }
                }
            ]
        },
        selectDate: moment().subtract(1, "d").format("YYYY-MM-DD")
      }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this._search()
            })
        }
    },
    activated() {
        let params = this.$route.params
        if (params) {
            if (params.keyword) {
                this.listQuery.keywords = params.keyword
            }
            if (params.selectedTime && params.selectedTime.length == 2) {
                this.selectedTime = params.selectedTime
            }
            this._search()
        } else {
            this._search()
        }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.startDate = timeArr[0] || ""
            this.listQuery.endDate = timeArr[1] || ""
        },
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        setSelectDate() {
            if (this.selectDate && moment(this.selectDate).isValid()) {
                this.listQuery.startDate = moment(this.selectDate).format("YYYY-MM-DD 00:00:00")
                this.listQuery.endDate = moment(this.selectDate).format("YYYY-MM-DD 23:59:59")
            }
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.startDate = psTime
            this.listQuery.endDate = peTime
            this.selectedTime = [this.listQuery.startDate, this.listQuery.endDate]
        },
        getDataList () {
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            funMaintenance.GetEmployeeDayWorks(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items.map(x => {
                        x.date = moment(x.date).format("YYYY-MM-DD")
                        if (x.startTime && x.endTime) {
                            let st = `${x.date} ${x.startTime}`
                            let et = `${x.date} ${x.endTime}`
                            x.durationDec = window.$util.converDuration(st, et, "h", true)
                            x.durationTxt = window.$util.converDuration(st, et, "h")
                            x.durationTxt = x.durationTxt || "0秒"
                        }
                        return x
                    })
                } else {
                    this.tableData = []
                }
            })
        },
        _updateState() {
            this.setSelectedTime()
            let params = {
                area: this.listQuery.area,
                startDate: this.listQuery.startDate,
                endDate: this.listQuery.endDate
            }
            if (!params.startDate || !params.endDate) {
                this.warningMsg("请选择更新时间段!")
                return false
            }
            funMaintenance.HandleDayWork(params).then(() => {
                this.getDataList()
                this.successMsg("工作状态更新成功!")
            })
        },
        tranceEmployeeDayWorkStatus(status) {
            let txt = ""
            let item = this.arrEmployeeDayWorkStatus && this.arrEmployeeDayWorkStatus.find(x => x.value === status)
            if (item) {
                txt = item.desc
            }
            return txt
        },
        _search() {
            this.listQuery.page = 1
            this.getDataList()
        },
        openExportExcel() {
            if (this.total > this.maxExportCount) {
                this.warningMsg("不允许导出超过2万条数据，如有需要，请联系管理员处理!")
                return false
            }
            let searchs = JSON.parse(JSON.stringify(this.listQuery))
            searchs.page = 1
            searchs.size = this.maxExportCount
            window.$common.fullLoading()
            funMaintenance.GetEmployeeDayWorks(searchs).then(res => {
                if (res && res.items && res.items.length > 0) {
                    let tableDatas = res.items.map(x => {
                        x.date = moment(x.date).format("YYYY-MM-DD")
                        if (x.startTime && x.endTime) {
                            let st = `${x.date} ${x.startTime}`
                            let et = `${x.date} ${x.endTime}`
                            x.durationDec = window.$util.converDuration(st, et, "h", true)
                            x.durationDec = Number(x.durationDec)
                            x.durationTxt = window.$util.converDuration(st, et, "h")
                            x.durationTxt = x.durationTxt || "0秒"
                        }
                        return x
                    })
                    let exportData = ""
                    if (searchs.startDate && searchs.endDate) {
                        let sbtd = moment(searchs.startDate).format("YYYY-MM-DD")
                        let ebtd = moment(searchs.endDate).format("YYYY-MM-DD")
                        exportData = sbtd == ebtd ? sbtd : `${sbtd}至${ebtd}`
                    } else {
                        exportData = "全部"
                    }
                    let exportName = `${this.$store.getters.serviceArea}的员工工作纪录表(${exportData}).xlsx`
                    EmployeeDayWorkLogs(exportName, tableDatas)
                } else {
                    window.$common.closeFullLoading()
                    setTimeout(() => {
                        this.alert("没有需要导出的数据!")
                    }, 10)
                }
            }).catch(() => {
                console.log("export error")
            })
        }
    }
  }
</script>